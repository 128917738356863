import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Register from "./Register";
import Login from "./Login";
import Forget from "./Forget";
import Dashboard from "./Dashboard";
import Perfil from "./Perfil";
import Liga from "./Liga";
import ResetPassword from "./ResetPassword";
import Jugadores from "./Jugadores";
import DetallesJugador from "./DetallesJugador";

function Home() {
  return (
    <>
      <header className="header">
        <h1 className="title">ColFantasy</h1>
        <p className="subtitle">
          ¡Vive la emoción del fútbol colombiano como nunca antes!
        </p>
        <div className="header-buttons">
          <Link to="/register">
            <button className="cta-button">Regístrate Ahora</button>
          </Link>
        </div>
        <p className="login-link">
          ¿Ya tienes una cuenta? <Link to="/login">Inicia sesión</Link>
        </p>
      </header>

      <main className="features">
        <h2>¿Qué es ColFantasy?</h2>
        <p>
          ColFantasy es el juego donde puedes crear tu equipo ideal del Fútbol
          Profesional Colombiano, competir con tus amigos y ganar.
        </p>
        <div className="cards">
          <div className="card">
            <div className="icon">⚽</div>
            <h3>Arma tu Equipo</h3>
            <p>
              Elige a tus jugadores favoritos y crea la alineación perfecta.
            </p>
          </div>
          <div className="card">
            <div className="icon">🏆</div>
            <h3>Compite</h3>
            <p>Participa en ligas con tus amigos y alcanza el liderato.</p>
          </div>
          <div className="card">
            <div className="icon">🔝</div>
            <h3>Gana</h3>
            <p>Acumula puntos y gana cada semana.</p>
          </div>
        </div>
      </main>

      <footer className="footer">
        <div className="social-links">
          <a
            href="https://x.com/ColFantasy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://www.instagram.com/colfantasy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="mailto:info.colfantasy@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gmail
          </a>
        </div>
        <p>&copy; 2024 ColFantasy. Todos los derechos reservados.</p>
      </footer>
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget" element={<Forget />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/perfil" element={<Perfil />} />
          <Route path="/liga" element={<Liga />} />
          <Route path="/jugadores" element={<Jugadores />} />
          <Route path="/detalles-jugador" element={<DetallesJugador />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
