import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Dashboard.css";
import { FaBars, FaTimes } from "react-icons/fa"; // Iconos para el menú hamburguesa

function Dashboard() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false); // Estado para abrir/cerrar el menú
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "ColFantasy | Perfil";

    const fetchUserData = async () => {
      try {
        const response = await axios.get("http://localhost:3000/auth/user/me", {
          withCredentials: true,
        });
        setUserData(response.data);
      } catch (err) {
        setError("No se pudo obtener los datos del usuario.");
        if (err.response && err.response.status === 401) {
          navigate("/login");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userData?.sub) {
        try {
          const response = await axios.get(
            `http://localhost:3000/usuario/getById/${userData.sub}`
          );
          setUserData((prevData) => ({
            ...prevData,
            username: response.data.username,
            email: response.data.email,
          }));
        } catch (err) {
          setError("No se pudo obtener la información completa del usuario.");
        }
      }
    };

    if (userData?.sub && !userData.username) {
      fetchUserDetails();
    }
  }, [userData]);

  const handleLogout = async () => {
    try {
      await axios.post(
        "http://localhost:3000/auth/logout",
        {},
        { withCredentials: true }
      );
      navigate("/login");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const handleGoToDashboard = () => {
    window.location.href = "http://localhost:3001/dashboard";
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Cambiar estado del menú
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="dashboard-container">
      {/* Navbar */}
      <nav className="navbar">
        <div className="navbar-logo">
          <img src="./logonobg.png" alt="Logo" />
        </div>
        <div className={`navbar-links ${menuOpen ? "active" : ""}`}>
          <ul>
            <li>
              <a href="/liga">Inicio</a>
            </li>
            <li>
              <a href="/mercado">Mercado</a>
            </li>
            <li>
              <a href="/fecha">Fecha</a>
            </li>
            <li>
              <a href="/jugadores">Jugadores</a>
            </li>
            <li>
              <a href="/perfil">Perfil</a>
            </li>
          </ul>
        </div>
        <div className="navbar-toggle" onClick={toggleMenu}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </nav>

      {/* Contenido de Dashboard */}
      <div className="content">
        <h1>Bienvenido, {userData?.username}!</h1>
        <p>Correo: {userData?.email}</p>
        <p>Más opciones y estadísticas pronto... ⌛</p>
      </div>

      <button className="button" onClick={handleGoToDashboard}>
        Ir al Dashboard
      </button>

      <button className="logout-button" onClick={handleLogout}>
        Cerrar Sesión
      </button>

      <footer className="footer">
        <div className="social-links">
          <a
            href="https://x.com/ColFantasy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://www.instagram.com/colfantasy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="mailto:info.colfantasy@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gmail
          </a>
        </div>
        <p>&copy; 2024 ColFantasy. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default Dashboard;
