import React, { useState } from "react";
import { Link, useNavigate  } from "react-router-dom";
import "./Register.css";

function Register() {
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Indica si se está procesando el registro
  const [successMessage, setSuccessMessage] = useState(""); // Mensaje de éxito
  const navigate = useNavigate(); // Usamos useNavigate para la redirección

  React.useEffect(() => {
    document.title = "ColFantasy | Registro";
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const validate = () => {
    const errors = {};
  
    if (!/^[a-zA-Z0-9_]{3,20}$/.test(form.name)) {
      errors.name =
        "El nombre de usuario debe tener entre 3 y 20 caracteres y solo puede incluir letras, números y guiones bajos.";
    }
  
    if (!form.email.trim()) {
      errors.email = "El correo es requerido";
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      errors.email = "El correo no es válido.";
    }
  
    if (!form.password.trim()) {
      errors.password = "La contraseña es requerida";
    }
  
    return errors;
  };  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage(""); // Resetea el mensaje de éxito
    setErrors({}); // Resetea los errores

    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        setLoading(true); // Muestra el indicador de carga
        const response = await fetch("https://colfantasy-backend-production.up.railway.app/usuario/register", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: form.name,
            email: form.email,
            password: form.password,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          if (response.status === 409) {
            setErrors({ email: errorData.message || "Correo ya registrado" });
          } else {
            setErrors({ general: "Ocurrió un error al registrar el usuario." });
          }
          return;
        }

        setSuccessMessage("Usuario registrado con éxito, redirigiendo al inicio de sesión...");
      
        // Redirigir después de un pequeño retraso
        setTimeout(() => {
          navigate("/login"); // Usamos navigate para redirigir
        }, 2000); // Retraso de 2 segundos
      } catch (error) {
        setErrors({ general: "Error al conectar con el servidor." });
      } finally {
        setLoading(false); // Oculta el indicador de carga
      }
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="register-container">
      <a href="https://www.colfantasy.fun">
        <img
          src="./logonobg.png"
          alt="ColFantasy Logo"
          className="register-logo"
        />
      </a>
      <h1>Registro</h1>
      <form onSubmit={handleSubmit}>
        {errors.general && <p className="error">{errors.general}</p>}
        {successMessage && <p className="success">{successMessage}</p>}
        <div className="form-group">
          <label htmlFor="name">Nombre:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={form.name}
            onChange={handleChange}
            required
          />
          {errors.name && <p className="error">{errors.name}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="email">Correo:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={form.email}
            onChange={handleChange}
            required
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="password">Contraseña:</label>
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={form.password}
              onChange={handleChange}
              required
            />
            <button
              type="button"
              className="toggle-password"
              onClick={toggleShowPassword}
            >
              {showPassword ? "🙈" : "👁️"}
            </button>
          </div>
          {errors.password && <p className="error">{errors.password}</p>}
        </div>
        <button type="submit" className="cta-button" disabled={loading}>
          {loading ? "Registrando..." : "Registrar"}
        </button>
        <p className="login-link">
          ¿Ya tienes una cuenta? <Link to="/login">Inicia sesión</Link>
        </p>
      </form>
      <footer className="footer">
        <div className="social-links">
          <a
            href="https://x.com/ColFantasy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://www.instagram.com/colfantasy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="mailto:info.colfantasy@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gmail
          </a>
        </div>
        <p>&copy; 2024 ColFantasy. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default Register;
