import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Jugadores.css";
import { FaBars, FaTimes } from "react-icons/fa";

function Jugadores() {
  const [leagueData, setLeagueData] = useState(null);
  const [jugadores, setJugadores] = useState([]);
  const [filteredJugadores, setFilteredJugadores] = useState([]);
  const [filter, setFilter] = useState("Todos");
  const [currentPage, setCurrentPage] = useState(1);
  const [jugadoresPorPagina, setJugadoresPorPagina] = useState(8);
  const [visiblePages, setVisiblePages] = useState(5);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const data = localStorage.getItem("leagueData");
    if (data) {
      setLeagueData(JSON.parse(data));
    } else if (location.state) {
      setLeagueData(location.state);
      localStorage.setItem("leagueData", JSON.stringify(location.state));
    }

    document.title = "ColFantasy | Jugadores";
  }, [location]);

  useEffect(() => {
    if (leagueData) {
      fetch(
        `http://localhost:3000/jugador/competicion/${leagueData.competitionId}`
      )
        .then((response) => response.json())
        .then((data) => {
          setJugadores(data);
          setFilteredJugadores(data);
        })
        .catch((error) => console.error("Error fetching jugadores:", error));
    }
  }, [leagueData]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setJugadoresPorPagina(6);
      } else {
        setJugadoresPorPagina(8);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFilterChange = (filter) => {
    setFilter(filter);
    if (filter === "Todos") {
      setFilteredJugadores(jugadores);
    } else {
      setFilteredJugadores(
        jugadores.filter((jugador) => jugador.posicion === filter)
      );
    }
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleJugadorClick = (id) => {
    navigate("/detalles-jugador", { state: { id } }); // Redirigir usando el estado para ocultar el ID
  };

  const indexOfLastPlayer = currentPage * jugadoresPorPagina;
  const indexOfFirstPlayer = indexOfLastPlayer - jugadoresPorPagina;
  const currentPlayers = filteredJugadores.slice(
    indexOfFirstPlayer,
    indexOfLastPlayer
  );

  const totalPages = Math.ceil(filteredJugadores.length / jugadoresPorPagina);

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const renderPageNumbers = () => {
    const pages = [];
    const halfVisible = Math.floor(visiblePages / 2);
    let startPage = currentPage - halfVisible;
    let endPage = currentPage + halfVisible;

    if (startPage <= 0) {
      startPage = 1;
      endPage = visiblePages;
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = totalPages - visiblePages + 1;
      if (startPage <= 0) {
        startPage = 1;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </button>
      );
    }

    return pages;
  };

  if (!leagueData) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="liga-container">
      <nav className="navbar">
        <div className="navbar-logo">
          <img src="./logonobg.png" alt="Logo" />
        </div>
        <div className={`navbar-links ${menuOpen ? "active" : ""}`}>
          <ul>
            <li>
              <a href="/liga">Inicio</a>
            </li>
            <li>
              <a href="/mercado">Mercado</a>
            </li>
            <li>
              <a href="/fecha">Fecha</a>
            </li>
            <li>
              <a href="/jugadores">Jugadores</a>
            </li>
            <li>
              <a href="/opciones">Opciones</a>
            </li>
            <li>
              <a href="/perfil">Perfil</a>
            </li>
          </ul>
        </div>
        <div className="navbar-toggle" onClick={toggleMenu}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </nav>

      <div className="content">
        <h2>Jugadores</h2>
        <h3>
          Haz click en la foto de un jugador o en el escudo de un equipo para
          ver más detalles.
        </h3>

        <div className="filter-options">
          <button onClick={() => handleFilterChange("Todos")}>Todos</button>
          <button onClick={() => handleFilterChange("Portero")}>
            Porteros
          </button>
          <button onClick={() => handleFilterChange("Defensa")}>
            Defensas
          </button>
          <button onClick={() => handleFilterChange("Volante")}>
            Volantes
          </button>
          <button onClick={() => handleFilterChange("Delantero")}>
            Delanteros
          </button>
          <button onClick={() => handleFilterChange("Técnico")}>
            Técnicos
          </button>
        </div>

        <div className="jugadores-list">
          {currentPlayers.length > 0 ? (
            currentPlayers.map((jugador) => (
              <div key={jugador.nombre} className="jugador-card">
                <img
                  src={jugador.foto}
                  alt={jugador.nombre}
                  className="jugador-foto"
                  onClick={() => handleJugadorClick(jugador.id)}
                  style={{ cursor: "pointer" }} // Asegura que el cursor cambie a "pointer"
                />
                <div className="jugador-info">
                  <p>
                    {jugador.nombre}, {jugador.posicion} de
                    <img
                      src={jugador.escudo}
                      alt="Escudo del equipo"
                      className="equipo-escudo"
                    />
                  </p>

                  <p>
                    {new Intl.NumberFormat("es-CO", {
                      style: "currency",
                      currency: "COP",
                      minimumFractionDigits: 0,
                    }).format(jugador.valor)}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p>No hay jugadores disponibles para esta competición.</p>
          )}
        </div>

        <div className="pagination">
          {currentPage > 1 && (
            <button onClick={() => handlePageChange(currentPage - 1)}>
              Anterior
            </button>
          )}
          {renderPageNumbers()}
          {currentPage < totalPages && (
            <button onClick={() => handlePageChange(currentPage + 1)}>
              Siguiente
            </button>
          )}
        </div>
      </div>

      <footer className="footer">
        <div className="social-links">
          <a
            href="https://x.com/ColFantasy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://www.instagram.com/colfantasy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="mailto:info.colfantasy@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gmail
          </a>
        </div>
        <p>&copy; 2024 ColFantasy. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default Jugadores;
