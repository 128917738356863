import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Register.css";

function Forget() {
  const [form, setForm] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(""); // Estado para mostrar el mensaje de éxito o error
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para saber si el formulario está siendo enviado

  React.useEffect(() => {
    document.title = "ColFantasy | Recuperar Contraseña";
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const validate = () => {
    const errors = {};

    if (!form.email.trim()) {
      errors.email = "El correo es requerido";
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      errors.email = "El correo no es válido";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true); // Inicia el estado de envío

      try {
        // Realiza la solicitud POST al servidor para enviar el correo de recuperación
        const response = await axios.post(
          "http://localhost:3000/auth/forgot-password",
          {
            email: form.email,
          }
        );

        // Manejar respuesta exitosa
        setMessage(
          "¡Revisa tu correo! Si la cuenta existe, recibirás un enlace para restablecer tu contraseña."
        );
      } catch (error) {
        // Manejar errores de solicitud o de servidor
        if (error.response) {
          setMessage(
            error.response.data.message || "Hubo un error en la solicitud."
          );
        } else {
          setMessage("Hubo un error en la conexión al servidor.");
        }
      } finally {
        setIsSubmitting(false); // Finaliza el estado de envío
      }
    }
  };

  return (
    <div className="register-container">
      <a href="https://www.colfantasy.fun">
        <img
          src="./logonobg.png"
          alt="ColFantasy Logo"
          className="register-logo"
        />
      </a>
      <h1>Recuperar Contraseña</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Correo:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={form.email}
            onChange={handleChange}
            required
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
        {message && (
          <p className="message" style={{ fontSize: "1.5em" }}>
            {message}
          </p>
        )}
        <button type="submit" className="cta-button" disabled={isSubmitting}>
          {isSubmitting ? "Enviando..." : "Enviar"}{" "}
          {/* Cambia el texto del botón */}
        </button>
        <br></br>
        <br></br>
        <p className="login-link">
          ¿No tienes una cuenta? <Link to="/register">Regístrate</Link>
        </p>
        <p className="login-link">
          <Link to="/login">Iniciar Sesión</Link>
        </p>
        <br></br>
        <br></br>
      </form>

      <footer className="footer">
        <div className="social-links">
          <a
            href="https://x.com/ColFantasy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://www.instagram.com/colfantasy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="mailto:info.colfantasy@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gmail
          </a>
        </div>
        <p>&copy; 2024 ColFantasy. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default Forget;
