import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Dashboard.css";

function Dashboard() {
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );
  const [leagues, setLeagues] = useState(
    JSON.parse(localStorage.getItem("leagues")) || []
  );
  const [loading, setLoading] = useState(!userData);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "ColFantasy | Inicio";

    const fetchUserData = async () => {
      try {
        const response = await axios.get("http://localhost:3000/auth/user/me", {
          withCredentials: true,
        });
        setUserData(response.data);
        localStorage.setItem("userData", JSON.stringify(response.data));
      } catch (err) {
        setError("No se pudo obtener los datos del usuario.");
        if (err.response && err.response.status === 401) {
          navigate("/login");
        }
      } finally {
        setLoading(false);
      }
    };

    if (!userData) {
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, [navigate, userData]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userData?.sub) {
        try {
          const response = await axios.get(
            `http://localhost:3000/usuario/getById/${userData.sub}`
          );
          const updatedUserData = {
            ...userData,
            username: response.data.username,
          };
          setUserData(updatedUserData);
          localStorage.setItem("userData", JSON.stringify(updatedUserData));
        } catch (err) {
          setError("No se pudo obtener la información completa del usuario.");
        }
      }
    };

    if (userData?.sub && !userData.username) {
      fetchUserDetails();
    }
  }, [userData]);

  useEffect(() => {
    const fetchUserLeagues = async () => {
      if (userData?.sub) {
        try {
          const response = await axios.get(
            `http://localhost:3000/usuario-liga/getLigasById/${userData.sub}`
          );
          setLeagues(response.data);
          localStorage.setItem("leagues", JSON.stringify(response.data));
        } catch (err) {
          if (err.response && err.response.status === 404) {
            setLeagues([]);
            localStorage.setItem("leagues", JSON.stringify([]));
          } else {
            setError("No se pudo obtener las ligas del usuario.");
          }
        }
      }
    };

    if (userData?.sub) {
      fetchUserLeagues();
    }
  }, [userData?.sub]);

  const handleLogout = async () => {
    try {
      await axios.post(
        "http://localhost:3000/auth/logout",
        {},
        { withCredentials: true }
      );
      localStorage.removeItem("userData");
      localStorage.removeItem("leagues");
      navigate("/login");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const handleLeagueClick = (
    leagueId,
    competitionId,
    namecompetition,
    nameleague
  ) => {
    navigate("/liga", {
      state: { leagueId, competitionId, namecompetition, nameleague },
    });
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="dashboard-container">
      <h1>
        Bienvenido, <a href={`/perfil`}>{userData?.username}</a>! 😎
      </h1>
      <p>
        Haz click en tu nombre de usuario para entrar a tu perfil.
        <br />
        Haz click en el nombre de una liga para entrar en ella.
      </p>
      <br />
      <div className="leagues-section">
        {leagues.length > 0 ? (
          <>
            <h3>Ligas en las que participas:</h3>
            <ul>
              {leagues.map((league, index) => (
                <li key={index}>
                  <strong>Nombre: </strong>
                  <span
                    onClick={() =>
                      handleLeagueClick(
                        league.idliga,
                        league.idcompeticion,
                        league.competicionnombre,
                        league.liganombre
                      )
                    }
                    style={{
                      cursor: "pointer",
                      color: "white",
                      textDecoration: "underline",
                    }}
                  >
                    {league.liganombre}
                  </span>{" "}
                  <br />
                  <strong>Competición:</strong> {league.competicionnombre}{" "}
                  <br />
                  <strong>Administrador:</strong>{" "}
                  {league.administrador === userData.sub ? (
                    <span style={{ color: "yellow", fontWeight: "bold" }}>
                      Sí
                    </span>
                  ) : (
                    "No"
                  )}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p>No estás participando en ninguna liga en este momento.</p>
        )}
      </div>
      <button className="logout-button" onClick={handleLogout}>
        Cerrar sesión
      </button>
      <footer className="footer">
        <div className="social-links">
          <a
            href="https://x.com/ColFantasy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://www.instagram.com/colfantasy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="mailto:info.colfantasy@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gmail
          </a>
        </div>
        <p>&copy; 2024 ColFantasy. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default Dashboard;
