import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Se importa useNavigate para la redirección
import axios from "axios"; // Usamos Axios para las peticiones HTTP
import "./Register.css";

function Login() {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(""); // Mensaje de éxito
  const navigate = useNavigate(); // Usamos el hook useNavigate para redirigir

  React.useEffect(() => {
    document.title = "ColFantasy | Inicio de Sesión";
  }, []);

  // Manejo de cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  // Validación del formulario antes de enviarlo
  const validate = () => {
    const errors = {};

    if (!form.email.trim()) {
      errors.email = "El correo es requerido";
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      errors.email = "El correo no es válido";
    }

    if (!form.password) {
      errors.password = "La contraseña es requerida";
    } else if (form.password.length < 8) {
      errors.password = "La contraseña debe tener al menos 8 caracteres";
    }

    return errors;
  };

  // Enviar los datos del formulario al backend para autenticar al usuario
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setSuccessMessage(""); // Resetea el mensaje de éxito
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true); // Indicamos que la solicitud está en proceso

      try {
        const response = await axios.post('http://localhost:3000/auth/login', form, { withCredentials: true });

        const { message } = response.data; // Si el servidor envía un mensaje de éxito

        // Mostrar mensaje de éxito y redirigir al usuario
        setSuccessMessage("Inicio de sesión exitoso, redirigiendo...");
        navigate("/dashboard"); // Redirigir a la página principal
      } catch (error) {
        setLoading(false); // Dejamos de mostrar el cargando

        if (error.response) {
          if (error.response.status === 401) {
            // Si el error es 401, muestra el mensaje de error detallado
            setErrors({ general: error.response.data.message });
          } else {
            setErrors({
              general: "Hubo un error en el servidor, intentalo más tarde.",
            });
          }
        } else {
          setErrors({
            general:
              "No se pudo conectar al servidor, por favor revisa tu conexión.",
          });
        }
      }
    }
  };

  // Alternar la visibilidad de la contraseña
  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="register-container">
      <a href="https://www.colfantasy.fun">
        <img
          src="./logonobg.png"
          alt="ColFantasy Logo"
          className="register-logo"
        />
      </a>
      <h1>Inicio de Sesión</h1>
      <form onSubmit={handleSubmit}>
        {errors.general && <p className="error">{errors.general}</p>}
        {successMessage && <p className="success">{successMessage}</p>}
        <div className="form-group">
          <label htmlFor="email">Correo:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={form.email}
            onChange={handleChange}
            required
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>

        <div className="form-group">
          <label htmlFor="password">Contraseña:</label>
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={form.password}
              onChange={handleChange}
              required
            />
            <button
              type="button"
              className="toggle-password"
              onClick={toggleShowPassword}
            >
              {showPassword ? "🙈" : "👁️"}
            </button>
          </div>
          {errors.password && <p className="error">{errors.password}</p>}
        </div>

        <button type="submit" className="cta-button" disabled={loading}>
          {loading ? "Cargando..." : "Iniciar Sesión"}
        </button>

        <p className="login-link">
          ¿No tienes una cuenta? <Link to="/register">Regístrate</Link>
        </p>
        <p className="login-link">
          <Link to="/forget">Olvidé mi contraseña</Link>
        </p>
      </form>

      <footer className="footer">
        <div className="social-links">
          <a
            href="https://x.com/ColFantasy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://www.instagram.com/colfantasy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="mailto:info.colfantasy@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gmail
          </a>
        </div>
        <p>&copy; 2024 ColFantasy. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default Login;
