import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "./Liga.css";
import { FaBars, FaTimes } from "react-icons/fa";

function Liga() {
  const location = useLocation();
  const [leagueData, setLeagueData] = useState(
    location.state || JSON.parse(localStorage.getItem("leagueData"))
  );

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (leagueData) {
      localStorage.setItem("leagueData", JSON.stringify(leagueData));
    }
  }, [leagueData]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const navigate = useNavigate();

  const handlePlayersClick = () => {
    navigate("/jugadores", {
      state: leagueData,
    });
  };

  if (!leagueData) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="liga-container">
      <nav className="navbar">
        <div className="navbar-logo">
          <img src="./logonobg.png" alt="Logo" />
        </div>
        <div className={`navbar-links ${menuOpen ? "active" : ""}`}>
          <ul>
            <li>
              <Link to="/liga">Inicio</Link>
            </li>
            <li>
              <Link to="/mercado">Mercado</Link>
            </li>
            <li>
              <Link to="/fecha">Fecha</Link>
            </li>
            <li>
              <Link to="/jugadores" onClick={handlePlayersClick}>
                Jugadores
              </Link>
            </li>
            <li>
              <Link to="/opciones">Opciones</Link>
            </li>
            <li>
              <Link to="/perfil">Perfil</Link>
            </li>
          </ul>
        </div>
        <div className="navbar-toggle" onClick={toggleMenu}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </nav>
      <div className="content">
        <h1>{leagueData.nameleague}</h1>
        {leagueData.leagueId ? (
          <>
            <h3>Competición: {leagueData.namecompetition}</h3>
            {leagueData.competitionId === "88127693-91a6-4029-9c22-57ac22e641d3" && (
              <>
                <h3>¡Ya está aquí la gran final de la Copa! 💚⚔️❤️</h3>
                <img src="./img/cupfinal.jpg" alt="Copa Colombia" />
              </>
            )}
          </>
        ) : (
          <p>No se proporcionó ID de la liga ni la competición.</p>
        )}
      </div>
      <footer className="footer">
        <div className="social-links">
          <a
            href="https://x.com/ColFantasy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://www.instagram.com/colfantasy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="mailto:info.colfantasy@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gmail
          </a>
        </div>
        <p>&copy; 2024 ColFantasy. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default Liga;
