import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Jugadores.css";
import { FaBars, FaTimes } from "react-icons/fa";

function Jugadores() {
  const [leagueData, setLeagueData] = useState(null);
  const [jugadores, setJugadores] = useState([]);
  const [filteredJugadores, setFilteredJugadores] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [jugadoresPorPagina, setJugadoresPorPagina] = useState(8);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const data = localStorage.getItem("leagueData");
    if (data) {
      setLeagueData(JSON.parse(data));
    } else if (location.state) {
      setLeagueData(location.state);
      localStorage.setItem("leagueData", JSON.stringify(location.state));
    }

    document.title = "ColFantasy | Detalles";
  }, [location]);

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  if (!leagueData) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="liga-container">
      <nav className="navbar">
        <div className="navbar-logo">
          <img src="./logonobg.png" alt="Logo" />
        </div>
        <div className={`navbar-links ${menuOpen ? "active" : ""}`}>
          <ul>
            <li>
              <a href="/liga">Inicio</a>
            </li>
            <li>
              <a href="/mercado">Mercado</a>
            </li>
            <li>
              <a href="/fecha">Fecha</a>
            </li>
            <li>
              <a href="/jugadores">Jugadores</a>
            </li>
            <li>
              <a href="/opciones">Opciones</a>
            </li>
            <li>
              <a href="/perfil">Perfil</a>
            </li>
          </ul>
        </div>
        <div className="navbar-toggle" onClick={toggleMenu}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </nav>

      <div className="content">
        <h2>Detalles del Jugador</h2>
        <h3>
          PONER LA FOTO, EL ESCUDO, EL PRECIO, LA POSICION Y LOS PUNTOS Y TODA LA ESTADISTICA
        </h3>
      </div>

      <footer className="footer">
        <div className="social-links">
          <a
            href="https://x.com/ColFantasy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://www.instagram.com/colfantasy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="mailto:info.colfantasy@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gmail
          </a>
        </div>
        <p>&copy; 2024 ColFantasy. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default Jugadores;
