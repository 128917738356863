import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Register.css";

const ResetPassword = () => {
  const { token } = useParams();
  const [error, setError] = useState("");
  const [validToken, setValidToken] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    document.title = "ColFantasy | Recuperar Contraseña";
  }, []);

  useEffect(() => {
    // Valida el token
    fetch(`http://localhost:3000/auth/validate-token/${token}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setValidToken(true); // Token válido, permite restablecer la contraseña
        } else {
          setValidToken(false);
          setError(data.message || "Error al verificar el token");
        }
      })
      .catch((err) => {
        setValidToken(false);
        setError("Hubo un error al validar el token");
      });
  }, [token]);

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!newPassword) {
      setError("Por favor ingresa una nueva contraseña");
      return;
    }

    // Envía la nueva contraseña al backend
    fetch(`http://localhost:3000/auth/reset-password/${token}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ newPassword }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          navigate("/login"); // Redirige a login si la contraseña se restableció correctamente
        } else {
          setError(data.message || "Error al restablecer la contraseña");
        }
      })
      .catch((err) => {
        setError("Hubo un error al restablecer la contraseña");
      });
  };

  if (!validToken) {
    return (
      <div>
        <a href="https://www.colfantasy.fun">
          <img
            src="../logonobg.png"
            alt="ColFantasy Logo"
            className="register-logo"
          />
        </a>
        <p style={{ fontSize: "45px" }}>{error}</p>
        <button className="cta-button" onClick={() => navigate("/login")}>
          Ir al Login
        </button>
      </div>
    );
  }

  return (
    <div className="register-container">
      <a href="https://www.colfantasy.fun">
        <img
          src="../logonobg.png"
          alt="ColFantasy Logo"
          className="register-logo"
        />
      </a>
      <h1>Restablecer Contraseña</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="newPassword">Nueva Contraseña</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={handlePasswordChange}
            required
          />
        </div>
        <br></br>
        <button type="submit" className="cta-button">
          Cambiar
        </button>
      </form>
      <footer className="footer">
        <div className="social-links">
          <a
            href="https://x.com/ColFantasy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://www.instagram.com/colfantasy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="mailto:info.colfantasy@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gmail
          </a>
        </div>
        <p>&copy; 2024 ColFantasy. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
};

export default ResetPassword;
